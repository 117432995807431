import { Component, OnInit } from '@angular/core';
import { Project } from '../project';
import { ProjectService } from '../project.service';
import { BoundText } from '@angular/compiler/src/render3/r3_ast';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: [ './dashboard.component.css' ]
})
export class DashboardComponent implements OnInit {
  projects: Project[] = [];

  slideIndex: number = 1;


  constructor(private projectService: ProjectService) { }

  ngOnInit() {
    this.getProjects();
  }

  ngAfterViewInit(){
    this.showSlides(1);
  }


  getProjects(): void {
    this.projectService.getProjects()
      .subscribe(projects => this.projects = projects.slice(0, 4));
  }
  
  plusSlides(n): void {
    this.showSlides(this.slideIndex += n);
  }

  currentSlide(n): void{
    this.showSlides(this.slideIndex = n);
  }

  showSlides(n): void{
    let i;
    let slides = document.getElementsByClassName("mySlides") as HTMLCollectionOf<HTMLElement>;
    let dots = document.getElementsByClassName("demo") as HTMLCollectionOf<HTMLInputElement>;
    let captionText = document.getElementById("caption");
    if(n>slides.length){
      this.slideIndex =1;
    }
    if(n<1){
      this.slideIndex = slides.length;
    } 
    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }
    for (i = 0; i < dots.length; i++) {
      dots[i].className = dots[i].className.replace(" active", "");
    }
    slides[this.slideIndex-1].style.display = "block";
    dots[this.slideIndex-1].className += " active";
    captionText.innerHTML = dots[this.slideIndex-1].alt ;
    //123
  }
  
  scrollToBest(): void{
    try 
    { 
      //let best = document.getElementById("best");
      window.scrollTo({left:0, top:10000, behavior:'smooth'});
     } catch (e) {
      window.scrollTo(0, 550);
      }
    //
    //best.scrollIntoView();
  }
}