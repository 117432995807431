import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-kornfactory',
  templateUrl: './kornfactory.component.html',
  styleUrls: ['./kornfactory.component.css']
})
export class KornfactoryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
