import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProjectsComponent } from './projects/projects.component';
import { DashboardComponent }   from './dashboard/dashboard.component';
import { ProjectDetailComponent } from './project-detail/project-detail.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { RayderComponent } from './rayder/rayder.component';
import { SlimeBasherComponent } from './slime-basher/slime-basher.component';
import { FloorLavaComponent } from './floor-lava/floor-lava.component';
import { NotSafeForTanksComponent } from './not-safe-for-tanks/not-safe-for-tanks.component';
import { ApacheComponent } from './apache/apache.component';
import { AboutMeComponent } from './about-me/about-me.component';
import { EngineComponent } from './engine/engine.component';
import { AndroidAppComponent } from './android-app/android-app.component';
import { KornfactoryComponent } from './kornfactory/kornfactory.component';
import { KunstComponent } from './kunst/kunst.component';

const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: 'about-me', component: AboutMeComponent},
  { path: 'projects', component: ProjectsComponent},
  { path: 'dashboard', component: DashboardComponent},
  { path: 'impressum', component: ImpressumComponent},
  { path: 'detail/rayder', component: RayderComponent},
  { path: 'detail/slime-basher', component: SlimeBasherComponent},
  { path: 'detail/floor-lava', component: FloorLavaComponent},
  { path: 'detail/nsft', component: NotSafeForTanksComponent},
  { path: 'detail/apache', component: ApacheComponent},
  { path: 'detail/engine', component: EngineComponent},
  { path: 'detail/android-app', component: AndroidAppComponent},
  { path: 'detail/kornfactory', component: KornfactoryComponent},
  { path: 'detail/kunst', component: KunstComponent},
  //{ path: 'detail/:id', component: ProjectDetailComponent },
  { path: '**', redirectTo: '/dashboard'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
