import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-me',
  templateUrl: './about-me.component.html',
  styleUrls: ['./about-me.component.css']
})
export class AboutMeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  scrollToPrem(): void{
   /* try 
    { 
      //let best = document.getElementById("best");
      window.scrollTo({left:0, top:10000, behavior:'smooth'});
     } catch (e) {
      window.scrollTo(0, 550);
      }
    //
    //best.scrollIntoView();
  */
      let premiere = document.getElementById("premiere");
      premiere.scrollIntoView();
      
  }

  scrollToSql(): void{
    let premiere = document.getElementById("sql");
    premiere.scrollIntoView();
      
  }

} 
