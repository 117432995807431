import { Component, OnInit } from '@angular/core';
import { Location} from '@angular/common';

@Component({
  selector: 'app-not-safe-for-tanks',
  templateUrl: './not-safe-for-tanks.component.html',
  styleUrls: ['./not-safe-for-tanks.component.css']
})
export class NotSafeForTanksComponent implements OnInit {

  constructor(
    private location: Location
  ) { }

  ngOnInit() {
  }

  goBack(): void{
    this.location.back();
  }
}
