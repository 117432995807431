import {Project} from './project';

export const PROJECTS: Project[] = [
    {id: 'rayder', name: 'Rayder', description: 'SpaceShooter für mich! (work in progress)',splashimg: '/assets/RY/RY_Titel.PNG'},
    {id: 'engine', name: 'Eine eigene Engine', description: 'Eine eigene 2D GameEngine (work in progress)', splashimg: '/assets/engine/engine_titel.png'},
    {id: 'android-app', name: 'Android-App', description: 'Ein lokaler Messengerdienst für Android, welcher die Google NearbyAPI nutzt (2019)', splashimg: '/assets/app/app_titelt.png'},
    {id: 'portfolio' , name: 'Portfolio', description: 'Diese Webseite ist mein neustes Projekt (work in progress)', splashimg: '/assets/PP/PP_Titelt.png'},
    {id: 'slime-basher', name: 'Slime Basher', description: 'Ein 3D Towerdefense Game in Unity (2018)', splashimg: '/assets/SB/SB_Menu.PNG'},
    {id: 'apache', name: 'Apache the Game', description: '2D ShootEmUp mit Phaser (2017)',splashimg: '/assets/AP/AP_Titelt.png'},
    {id: 'floor-lava', name: 'Floor is Lava', description: 'Physikbasiertes 3D Jump and Run in Unity (2018)',splashimg: '/assets/FL/FL_Titlet.png'},
    {id: 'nsft', name: 'Not Safe for Tanks', description: 'Multiplayer 3D Panzerrennen in Unity (2017-18)',splashimg: '/assets/NSFT/NSFT_Titelt.png'},
    //{id: '14', name: 'Monopoly', description: 'Java-based digital boardgame',splashimg: '/assets/slime_basher.PNG'},
    //{id: '15', name: 'ASP.Net Alumni', description: 'Webproject in ASP.NET',splashimg: '/assets/slime_basher.PNG'},
    //{id: '16', name: 'Indian ShortMovie', description: 'Drama shortmovie in Hindi',splashimg: '/assets/slime_basher.PNG'},
    //{id: '17', name: 'Arbeiten mit Blender', description: 'Animationen und 3D Modelle (2017-2019)',splashimg: '/assets/slime_basher.PNG'},
    //{id: 'kornfactory', name: 'Kornfactory', description: 'Gestaltung einer imaginären Firma (2017)',splashimg: '/assets/slime_basher.PNG'},
    //{id: 'kunst', name: 'Künstlerische Projekte', description:'Praktische arbeiten in der Steinbildhauerei, sowie Zeichnungen (2009-2019)',splashimg: '/assets/slime_basher.PNG'}
];