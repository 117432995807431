import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Project} from './project';
import { PROJECTS } from './mock-projects';
import { MessageService } from './message.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  getProjects(): Observable<Project[]> {
    this.messageService.add('ProjectService: fetched projects');
    return of(PROJECTS);
  }

  getProject(id: string): Observable<Project> { //id: number
    this.messageService.add(`ProjectService: fetched Project id=${id}`);
    return of(PROJECTS.find(project => project.id === id));
  }

  constructor(private messageService: MessageService) { }
}
