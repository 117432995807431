import { Component, OnInit } from '@angular/core';
import { Location} from '@angular/common';

@Component({
  selector: 'app-floor-lava',
  templateUrl: './floor-lava.component.html',
  styleUrls: ['./floor-lava.component.css']
})
export class FloorLavaComponent implements OnInit {

  constructor(
    private location: Location
  ) { }

  ngOnInit() {
  }

  goBack(): void{
    this.location.back();
  }
}
