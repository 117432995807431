import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-rayder',
  templateUrl: './rayder.component.html',
  styleUrls: ['./rayder.component.css']
})
export class RayderComponent implements OnInit {

  constructor(
    private location: Location
  ) { }

  ngOnInit() {
  }

  goBack(): void{
    this.location.back();
  }

}
