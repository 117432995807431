import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ProjectsComponent } from './projects/projects.component';
import { ProjectDetailComponent } from './project-detail/project-detail.component';
import { MessagesComponent } from './messages/messages.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { RayderComponent } from './rayder/rayder.component';
import { SlimeBasherComponent } from './slime-basher/slime-basher.component';
import { FloorLavaComponent } from './floor-lava/floor-lava.component';
import { NotSafeForTanksComponent } from './not-safe-for-tanks/not-safe-for-tanks.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { ApacheComponent } from './apache/apache.component';
import { BlenderComponent } from './blender/blender.component';
import { AboutMeComponent } from './about-me/about-me.component';
import { EngineComponent } from './engine/engine.component';
import { AndroidAppComponent } from './android-app/android-app.component';
import { KornfactoryComponent } from './kornfactory/kornfactory.component';
import { KunstComponent } from './kunst/kunst.component';
import { PortfolioProjectComponent } from './portfolio-project/portfolio-project.component';

@NgModule({
  declarations: [
    AppComponent,
    ProjectsComponent,
    ProjectDetailComponent,
    MessagesComponent,
    DashboardComponent,
    RayderComponent,
    SlimeBasherComponent,
    FloorLavaComponent,
    NotSafeForTanksComponent,
    ImpressumComponent,
    ApacheComponent,
    BlenderComponent,
    AboutMeComponent,
    EngineComponent,
    AndroidAppComponent,
    KornfactoryComponent,
    KunstComponent,
    PortfolioProjectComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
